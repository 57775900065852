body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

  
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .back-drop-blur {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }
  }
  
  /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .back-drop-blur {
      background-color: rgba(255, 255, 255, .92);
    }
  }


.alert-enter {
  filter: blur(4 px);
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  filter: blur(4 px);
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  filter: blur(4 px);
  opacity: 1;
}
.alert-exit-active {
  filter: blur(4 px);
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
